const ImageDataDict = [
    {
        image_src: "kids1.JPG",
        image_legend: "Our robotics team hosting the FLL team!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids2.JPG",
        image_legend: "Inspiring presentation by the FLL team sharing their research!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids3.JPG",
        image_legend: "Proud to host the FLL team as they showcased their incredible project with us today!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids4.JPG",
        image_legend: "FLL team presents their amazing work!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids5.JPG",
        image_legend: "Exciting day as the FLL team presented their project!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids6.JPG",
        image_legend: "Grateful to the FLL team for sharing their hard work with us!",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids7.JPG",
        image_legend: "It was amazing to see the FLL team presenting their innovative solutions.",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids8.JPG",
        image_legend: "An inspiring moment watching the FLL team present their projects",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "kids10.JPG",
        image_legend: "Our robotics team had a great time with the FLL team.",
        year: "2024",
        path: "2024_fll_team_present",
        name: "2024 FLL Team Present"
    },
    {
        image_src: "d1.png",
        image_legend: "Our robotics team is hard at work in the pit, making adjustments to our robot at regionals in Duluth",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d2.png",
        image_legend: "Preparing in the pit at the Duluth Regional",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d3.png",
        image_legend: "fine-tuning our robot in the pit at the Duluth Regional",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d5.png",
        image_legend: "Our team posing with our robot in the pit at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d6.png",
        image_legend: "Our team preparing our robot before competition at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d7.png",
        image_legend: "Our mentors eating lunch together at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d8.png",
        image_legend: "The girls eating lunch together at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d9.png",
        image_legend: "Working on the robot in the pit at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d10.png",
        image_legend: "Fixing up the robot in the pit at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },

    {
        image_src: "d12.png",
        image_legend: "Our team in the arena watching our robot compete at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d13.png",
        image_legend: "Our team cheering on our robot as it competes at the Duluth Regionals!",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d14.png",
        image_legend: "Our team posing outside of the hotel at Duluth Regionals!",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d15.png",
        image_legend: "Some of our team posing outside of the competition arena at Duluth Regionals!",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d16.png",
        image_legend: "Our team in the competion arena at Duluth Regionals!",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d17.png",
        image_legend: "Some of our team enjoying eating lunch together at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d20.jpeg",
        image_legend: "Eating out as a team at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d21.jpeg",
        image_legend: "Our robotics team is hard at work in the pit, making adjustments to our robot at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d22.jpeg",
        image_legend: "The team takes a close-up shot of the robot while making final adjustments in the pit at Duluth Regionals",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d23.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d24.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d25.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d26.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d27.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d28.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d29.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d30.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d31.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d32.jpeg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d33.jpg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "d34.jpg",
        image_legend: "",
        year: "2024",
        path: "2024_duluth_regional",
        name: "2024 Duluth Regional"
    },
    {
        image_src: "so1.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_state_fair",
        name: "2024 State Fair"
    },
    {
        image_src: "so2.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_state_fair",
        name: "2024 State Fair"
    },
    {
        image_src: "so3.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_state_fair",
        name: "2024 State Fair"
    },
    {
        image_src: "so4.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_state_fair",
        name: "2024 State Fair"
    },
    {
        image_src: "so5.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_state_fair",
        name: "2024 State Fair"
    },
    {
        image_src: "af1.JPG",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_asian_fair",
        name: "2024 Asian Fair"
    },
    {
        image_src: "af2.jpg",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_asian_fair",
        name: "2024 Asian Fair"
    },
    {
        image_src: "af3.jpg",
        image_legend: "legend 4",
        year: "2024",
        path: "2024_asian_fair",
        name: "2024 Asian Fair"
    },
    {
        image_src: "h1.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h2.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h3.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h4.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h5.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h6.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h7.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h8.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h9.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h10.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h11.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h12.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h13.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h14.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h15.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h16.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h17.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h18.png",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h19.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h20.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h21.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h22.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "h23.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_houston_worlds",
        name: "2023 Houston Worlds"
    },
    {
        image_src: "ba1.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_robotics_banquet",
        name: "2024 Robotics Banquet",
    },
    {
        image_src: "ba2.jpg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_robotics_banquet",
        name: "2024 Robotics Banquet",
    },
    {
        image_src: "ba3.jpg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_robotics_banquet",
        name: "2024 Robotics Banquet",
    },
    {
        image_src: "ba4.jpg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_robotics_banquet",
        name: "2024 Robotics Banquet",
    },
    {
        image_src: "ba5.jpg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_robotics_banquet",
        name: "2024 Robotics Banquet",
    },
    {
        image_src: "pp1.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_plymouth_parade",
        name: "2023 Plymouth Parade",
    },
    {
        image_src: "pp2.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_plymouth_parade",
        name: "2023 Plymouth Parade",
    },
    {
        image_src: "pp3.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_plymouth_parade",
        name: "2023 Plymouth Parade",
    },
    {
        image_src: "pp4.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_plymouth_parade",
        name: "2023 Plymouth Parade",
    },
    {
        image_src: "m1.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m2.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m3.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m4.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m5.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m6.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m7.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m8.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
    {
        image_src: "m9.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_medtronic_jumpstart",
        name: "2024 Medtronic Jumpstart",
    },
   
    {
        image_src: "med1.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_medtronic_jumpstart",
        name: "2023 Medtronic Jumpstart",
    },
    {
        image_src: "med2.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_medtronic_jumpstart",
        name: "2023 Medtronic Jumpstart",
    },
    {
        image_src: "med3.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_medtronic_jumpstart",
        name: "2023 Medtronic Jumpstart",
    },
    {
        image_src: "med4.jpg",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_medtronic_jumpstart",
        name: "2023 Medtronic Jumpstart",
    },
    {
        image_src: "ft1.JPG",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_football_game",
        name: "2024 Football Game",
    },
    {
        image_src: "ft2.JPG",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_football_game",
        name: "2024 Football Game",
    },
    {
        image_src: "ft3.JPG",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_football_game",
        name: "2024 Football Game",
    },
    {
        image_src: "ft4.JPG",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_football_game",
        name: "2024 Football Game",
    },
    {
        image_src: "ft5.JPG",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_football_game",
        name: "2024 Football Game",
    },
    {
        image_src: "bss1.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "bss1.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "bss2.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "bss3.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "bss4.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "bss5.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_2025_build_season",
        name: "2024-2025 Build Season",
    },
    {
        image_src: "s1.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_sunset_hill_fair",
        name: "2024 March Sunset Hill STEAM fair",
    },
    {
        image_src: "s2.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_sunset_hill_fair",
        name: "2024 March Sunset Hill STEAM fair",
    },
    {
        image_src: "s3.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_sunset_hill_fair",
        name: "2024 March Sunset Hill STEAM fair",
    },
    {
        image_src: "s4.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_sunset_hill_fair",
        name: "2024 March Sunset Hill STEAM fair",
    },
    {
        image_src: "s5.jpeg",
        image_legend: "Legend 1",
        year: "2024",
        path: "2024_sunset_hill_fair",
        name: "2024 March Sunset Hill STEAM fair",
    },
    {
        image_src: "bs1.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs2.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs3.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs4.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs5.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs6.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs7.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs8.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs9.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs10.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs11.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs12.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs13.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs14.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs15.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
    {
        image_src: "bs16.JPG",
        image_legend: "Legend 1",
        year: "2023",
        path: "2023_2024_build_season",
        name: "2023-2024 Build Season",
    },
]
export default ImageDataDict;

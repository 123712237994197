const mentors = [
    {   
        name: "Ken Pashina",
        title: "Head Coach",
        desc: "Ken is the head coach for 2020-2021.",
        imgSrc: "studentImages/wayzata.png",
        year: "2021"
    },
    {    
        name: "Nira Rajan",
        title: "Assistant Coach",
        desc: "Nira has been mentoring with the team for 2 years now, and is the assistant coach! Outside of robotics she loves spending time outdoors with her boys!",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2021"
    },
    {    
        name: "Rob Simon",
        title: "Software and Mechanical Mentor",
        desc: "With a deep commitment to fostering creativity and teamwork, he finds immense joy in guiding his students through the challenges of building cutting-edge robots.",
        imgSrc: "/mentorImages/rob_simon.jpg",
        year: "2021"
    },
    {    
        name: "Nate Peterson",
        title: "Electrical & Programming Mentor",
        desc: "Nate is thrilled to be mentoring a talented robotics team, where his passion for technology and innovation meets the enthusiasm of the next generation of engineers.",
        imgSrc: "/mentorImages/natePeterson.jpg",
        year: "2021"
    },
    {    
        name: "Brian Bierman",
        title: "Mechanical Mentor",
        desc: "His passion for engineering and problem-solving fuels his dedication to guiding the team through the intricacies of mechanical design and bringing their robotic visions to life.",
        imgSrc: "/mentorImages/brianBierman.jpg",
        year: "2021"
    },
    {    
        name: "Raj Rajan",
        title: "Assistant Coach",
        desc: "Raj has been with Trojan Robotics for 2 years now. He is a mechanical engineer and enjoys building the robot with students!",
        imgSrc: "/mentorImages/arulRajan.jpg",
        year: "2021"
    },
    {    
        name: "Ken Pashina",
        title: "Head Coach",
        desc: "Ken is the head coach for 2021-2022.",
        imgSrc: "/studentImages/wayzata.png",
        year: "2022"
    },
    {    
        name: "Nira Rajan",
        title: "Assistant Coach",
        desc: "Nira is ecstatic to be helping the students grow by learning about robotics. She is a key part to the success of this team.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2022"
    },
    {     
        name: "Rob Simon",
        title: "Software and Mechanical Mentor",
        desc: "With a deep commitment to fostering creativity and teamwork, he finds immense joy in guiding his students through the challenges of building cutting-edge robots.",
        imgSrc: "/mentorImages/rob_simon.jpg",
        year: "2022"
    },
    {
        name: "Nate Peterson",
        title: "Electrical & Programming Mentor",
        desc: "Nate is thrilled to be mentoring a talented robotics team, where his passion for technology and innovation meets the enthusiasm of the next generation of engineers.",
        imgSrc: "/mentorImages/natePeterson.jpg",
        year: "2022"
    },
    {
        name: "Brian Bierman",
        title: "Mechanical Mentor",
        desc: "His passion for engineering and problem-solving fuels his dedication to guiding the team through the intricacies of mechanical design and bringing their robotic visions to life.",
        imgSrc: "/mentorImages/brianBierman.jpg",
        year: "2022"
    },
    {    
        name: "Jenny Zeroni",
        title: "Marketing Mentor",
        desc: "Jenny is the VP of Engineering and Operations at Venturemed Group. She has been with this team for 1 year, and is very instumental in helping the marketing team fundraise for money.",
        imgSrc: "/mentorImages/jennyZeroni.jpg",
        year: "2022"
    },
    {
        name: "Raj Rajan",
        title: "Assistant Coach",
        desc: "Raj has been with Trojan Robotics for 3 years now. He is a mechanical engineer and enjoys building the robot with students!",
        imgSrc: "/mentorImages/arulRajan.jpg",
        year: "2022"
    },
    {
        name: "Ken Pashina",
        title: "Head Coach",
        desc: "Ken is the head coach for 2022-2023.",
        imgSrc: "/studentImages/wayzata.png",
        year: "2023"
    },
    {           
        name: "Nira Rajan",
        title: "Assistant Coach",
        desc: "Nira is very happy to come to practice in person, after Covid. She is very thrilled to be at robotics and constanly helping students by supporting them. As well as that, she fosters a collaborative environment where students innovate and excel in robotics competitions.",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2023"
    },
    {
        name: "Satish Nayak",
        title: "Mechanical Mentor",
        desc: "With a background in engineering, he fosters a collaborative environment that encourages problem-solving and innovation among team members.",
        imgSrc: "/mentorImages/assistantCoach.jpg",
        year: "2023"
    },
    {
        name: "Raj Rajan",
        title: "Assistant Coach",
        desc: " He brings his expertise in mechanical engineering to help students build and refine their robots. Passionate about technology and teamwork, he works closely with the team to develop their skills.",
        imgSrc: "/mentorImages/arulRajan.jpg",
        year: "2023"
    },
    {
        name: "Rob Simon",
        title: "Software and Mechanical Mentor",
        desc: "He combines his expertise in engineering and coding to guide students through both the design and programming processes. With a deep passion for technology, he empowers the team to tackle complex challenges and develop innovative solutions.",
        imgSrc: "/mentorImages/rob_simon.jpg",
        year: "2023"
    },
    {
        name: "Nate Peterson",
        title: "Electrical & Programming Mentor",
        desc: "He helps guide students through the design and programming of competitive robots, fostering a culture of learning and innovation.",
        imgSrc: "/mentorImages/natePeterson.jpg",
        year: "2023"
    },
    {
        name: "Brian Bierman",
        title: "Mechanical Mentor",
        desc: "He uses his extensive knowledge of engineering to guide students in building and refining robot structures. His hands-on approach helps students understand the intricacies of mechanical design while fostering creativity and problem-solving skills.",
        imgSrc: "/mentorImages/brianBierman.jpg",
        year: "2023"
    },
    {
        name: "Jenny Zeroni",
        title: "Marketing Mentor",
        desc: "She helps students develop strategies to promote their projects and engage with the community. With a background in communications and branding, she teaches the team how to effectively showcase their work and build a strong presence both online and offline.",
        imgSrc: "mentorImages/jennyZeroni.jpg",
        year: "2023"
    },    
    {    
        name: "Nira Rajan",
        title: "Head Coach",
        desc: "Nira has been mentoring with the team for 5 years now, and is now the head coach! Outside of robotics she loves spending time outdoors with her boys!",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2024"
    },
    {    
        name: "Satish Nayak",
        title: "Assistant Coach",
        desc: "Satish is a project manager at Medtronic and enjoys helping make improvements to streamline this team. He was a pivotal driver in raising funds last year, allowing the team to attend Houston.",
        imgSrc: "mentorImages/assistantCoach.jpg",
        year: "2024"
    },
    {
        name: "Raj Rajan",
        title: "Assistant Coach",
        desc: "Raj has been with Trojan Robotics for 5 years now. He is a mechanical engineer and enjoys building and fabricating. In his spare time he enjoys spending time with his two boys outside! ",
        imgSrc: "/mentorImages/arulRajan.jpg",
        year: "2024"
    },
    {        
        name: "Robyn Bjella",
        title: "Assistant Coach",
        desc: "This is the third year Robyn has been with the team and is a pivotal driver in this team's outreach. In her spare time Robyn loves to volunteer for the school district and design buildings.",
        imgSrc: "/mentorImages/assistantCoachTwo.jpg",
        year: "2024"
    },
    {
        name: "Rob Simon",
        title: "Software and Mechanical Mentor",
        desc: "Rob is a legacy mentor and enjoys spending time with students and teaching them new things. Rob is also a engineer in the industry and this year he was pivotal to helping us build our new robot cart from a old stretcher bed.",
        imgSrc: "mentorImages/rob_simon.jpg",
        year: "2024"
    },
    {
        name: "Nate Peterson",
        title: "Electrical and Programming Mentor",
        desc: "Nate has been with the team for a very long time and has been the driving force of the team's software and electrtical success. He has always been there to bridge the knowledge gap of outgoing and new members year after year.", 
        imgSrc: "mentorImages/natePeterson.jpg",
        year: "2024"    
    },
    {
        name: "Brian Bierman",
        title: "Mechanical Mentor",
        desc: "Brian has been with the team for a very long time and has always been instrumental for us getting a functioning robot produced and in a timley manner. Fun Fact: His daugher was the head captain of this team when she was in high school!",
        imgSrc: "mentorImages/brianBierman.jpg",
        year: "2024"
    },
    {
        name: "Jenny Zeroni",
        title: "Marketing Mentor",
        desc: "Jenny is a vice president at a medical device startup, and enjoys fabricating various different innovations. This is her fourth year with the team and is a key part in our marketing team's success.",
        imgSrc: "mentorImages/jennyZeroni.jpg",
        year: "2024"
    },
    {
        name: "Mahima Nayak",
        title: "Outreach Mentor and Marketing Mentor",
        desc: "Mahima is an IT professional working at Medtronic. This is her second year working with FIRST robotics, and she enjoy working with creative individuals who think outside the box and learn throuh their experiences.",
        imgSrc: "mentorImages/mahimaNayak.jpg",
        year: "2024"
    },
    {
        name: "Dilshan Rajan",
        title: "Mechanical and Electrical Mentor",
        desc: "Dilshan is a neuroscience student at the University of Minnesota Twin Cities. He was the former head captain of this team and enjoys building and working with heavy machinery. ",
        imgSrc: "/mentorImages/dilshanRajan.jpg",
        year: "2024"
    },
    {
        name: "Dipesh Panchal",
        title: "Website and Programming Mentor",
        desc: "Dipesh has been mentoring with FIRST for four years and is now with us for robotics. Outside of robotics he loves spending time with his family and microcontrollers.",
        imgSrc: "/mentorImages/dipeshPanchal.JPG",
        year: "2024"    
    },
    {    
        name: "Nira Rajan",
        title: "Head Coach",
        desc: "Nira is currently the head coach, She is very committed to the team and has been with the team for 7 years. Other then being in robotics she loves to garden, cook, bake, and spend time with her family!",
        imgSrc: "/mentorImages/headCoach.jpg",
        year: "2025"
    },
    {
        name: "Satish Nayak",
        title: "Assistant Coach",
        desc: "Satish is a project manager at Medtronic and enjoys helping make improvements to streamline this team. Satish also likes to help the students explore using different tools.",
        imgSrc: "/mentorImages/assistantCoach.jpg",
        year: "2025"
    },
    {
        name: "Raj Rajan",
        title: "Assistant Coach",
        desc: "Raj has been with Trojan Robotics for 7 years now. He is a mechanical engineer and enjoys building the robot with students!",
        imgSrc: "/mentorImages/arulRajan.jpg",
        year: "2025"
    },
    {
        name: "Rob Simon",
        title: "Software and Mechanical Mentor",
        desc: "Rob is a legacy mentor and enjoys spending time with students and teaching them new things. Rob is also a engineer in the industry and help the students navigate through CAD.",
        imgSrc: "/mentorImages/rob_simon.jpg",
        year: "2025"
    },
    {
        name: "Nate Peterson",
        title: "Electrical & Programming Mentor",
        desc: "Nate has been a driving force for the team's software and electrical success for many years. He has always been there to bridge the knowledge gap of outgoing and new members year after year.",
        imgSrc: "/mentorImages/natePeterson.jpg",
        year: "2025"
    },
    {
        name: "Brian Bierman",
        title: "Mechanical Mentor",
        desc: "Brian has been intrumental to our team for several years. In helping students create a functioning robot in a timely manner.",
        imgSrc: "/mentorImages/brianBierman.jpg",
        year: "2025"
    },
    {
        name: "Mahima Nayak",
        title: "Outreach Mentor",
        desc: "Mahima is a software engineer at Medtonic. This is her second year helping Trojan Robotics, and she has been influential in maintaining the outreach team.",
        imgSrc: "/mentorImages/mahimaNayak.jpg",
        year: "2025"
    },
    {
        name: "Dilshan Rajan",
        title: "Mechanical, Electrical, and Marketing Mentor",
        desc: "Dilshan is a junior at the UMN majoring in physiology and psychology. In the future, he plans to do an MD/PhD in biomedical engineering. He was the former head captain of this team and continues to come back and passionately mentors students.",
        imgSrc: "/mentorImages/dilshanRajan.jpg",
        year: "2025"
    },
    {
        name: "Dipesh Panchal",
        title: "Website and Programming Mentor",
        desc: "Dipesh has been an instumental FLL, FRC, and FTC Coach for 7+ years. Dipesh has been a master behind creating the fabulous webpage for Trojan Robotics. Outside of robotics he loves spending time with his family.",
       imgSrc: "/mentorImages/dipeshPanchal.JPG",
       year: "2025"
    },
    {
        name: "Jenny Zeroni",
        title: "Former Marketing Mentor",
        desc: "Jenny is the VP of Engineering and Operations at Venturemed Group. She has been with this team for three years, and is very instumental in helping the marketing team fundraise for money.",
        imgSrc: "/mentorImages/jennyZeroni.jpg",
        year: "2025"
    }    
    


]

export default mentors;
const events = [

    {
        name: "10,000 Lakes Regional Competition",
        month: "March",
        date: "26-29",
        location: "MN State Fairgrounds",
    },
    {
        name: "Asian Fair",
        month: "May",
        date: "31",
        location: "Hilde Performance Center",
    },
    {
        name: "Plymouth on Parade",
        month: "Sep",
        date: "",
        location: "Plymouth, MN",
    },
    {
        name: "James J. Hill Days",
        month: "Sep",
        date: "5-7",
        location: "Downtown Wayzata",

    },
   
]

export default events;
